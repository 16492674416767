<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h4 class="text-subtitle-2" v-text="$t('project.competition.labels.matchScore')" />
      </v-col>
    </v-row>
    <v-row v-if="!canEdit">
      <v-col cols="12">
        <div class="d-flex align-center">
          <div style="width: 40px" class="mr-4">
            <v-icon x-large color="pending" v-text="'warning_outline'" />
          </div>
          <div v-text="$t('project.competition.match.errorMatchEdit')" />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model.number="form.team0Score"
          :error-messages="getFieldErrors('team0Score')"
          :label="match.team0.name"
          type="number" min="1" step="1"
          outlined dense class="mt-2" :disabled="!canEdit"
          @blur="$v.form.team0Score.$touch()" @keypress="validateKeyNumeric($event)"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="form.team1Score"
          :error-messages="getFieldErrors('team1Score')"
          :label="match.team1.name"
          type="number" min="1" step="1"
          outlined dense class="mt-2" :disabled="!canEdit"
          @blur="$v.form.team1Score.$touch()" @keypress="validateKeyNumeric($event)"
        />
        <a
          v-if="match.score && canEdit"
          color="primary" min-width="120" text class="float-right"
          @click="deleteScore" v-text="$t('project.competition.match.deleteResults')"
        />
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col cols="12">
        <v-btn
          color="primary"
          min-width="120"
          depressed
          :disabled="(form.team0Score == null || form.team1Score == null) || (form.team0Score < 0 || form.team1Score < 0)"
          @click="save()" v-text="$t('common.save')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'
import { FieldValue } from '@/services/firebase'

export default {
  name: 'EditMatchScore',
  mixins: [formUtils],
  props: {
    match: {
      type: Object,
      required: true,
    },
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      form: {
        team0Score: null,
        team1Score: null,
      },
      canEdit: false,
    }
  },
  validations() {
    return {
      form: {
        team0Score: { required, numeric },
        team1Score: { required, numeric },
      },
    }
  },
  async created() {
    this.form.team0Score = this.match.team0?.score
    this.form.team1Score = this.match.team1?.score
    const { organizationId, projectId } = this
    const { cohortId } = this.match
    this.canEdit = this.match.dbStatus === 'finished'
      ? await this.$store.dispatch('competition/canMatchBeEdited', { organizationId, projectId, cohortId, matchId: this.match.id })
      : true
  },
  methods: {
    async save() {
      if (!this.isFormValid()) {
        this.scrollToFirstError()
        return
      }
      const { id: matchId, team0, team1, dbStatus, cohortId } = this.match
      const { organizationId, projectId } = this
      const data = {
        [`teamsById.${team0.id}.score`]: this.form.team0Score,
        [`teamsById.${team1.id}.score`]: this.form.team1Score,
        status: 'finished',
      }
      this.runAsync(async () => {
        await this.$store.dispatch('competition/updateMatch', { organizationId, projectId, cohortId, matchId, data })
        const pathStore = (dbStatus === 'notStarted') ? 'competition/finalizeMatch' : 'competition/recalculateAfterMatchEdit'
        await this.$store.dispatch(pathStore, { organizationId, projectId, cohortId, matchId })
      })
    },
    deleteScore() {
      const { id: matchId, team0, team1, cohortId } = this.match
      const { organizationId, projectId } = this
      const data = {
        [`teamsById.${team0.id}.score`]: FieldValue.delete(),
        [`teamsById.${team1.id}.score`]: FieldValue.delete(),
        status: 'notStarted',
      }
      this.runAsync(async () => {
        await this.$store.dispatch('competition/updateMatch', { organizationId, projectId, cohortId, matchId, data })
        await this.$store.dispatch('competition/recalculateAfterMatchEdit', { organizationId, projectId, cohortId, matchId })
        this.form.team0Score = null
        this.form.team1Score = null
      })
    },
  },
}
</script>
